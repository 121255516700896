<script setup>
import { getRuntimeUniqueId } from '@/Utils/helpers';
const id = getRuntimeUniqueId();
</script>

<template>
	<svg
		width="173"
		height="240"
		viewBox="0 0 173 240"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.5952 9.80687H6.10605V16.3476C6.10605 16.618 6.05259 16.794 5.94079 16.8755C5.82899 16.9571 5.61511 17 5.2943 17H3.81662C3.4958 17 3.27707 16.9571 3.16527 16.8755C3.05347 16.794 3 16.618 3 16.3476V1.65236C3 1.38197 3.05347 1.20601 3.16527 1.12446C3.27707 1.04292 3.49094 1 3.81662 1H13.2612C13.5674 1 13.7667 1.04721 13.859 1.14592C13.9514 1.24464 14 1.43348 14 1.71674V2.70386C14 2.98712 13.9514 3.18026 13.859 3.27468C13.7667 3.37339 13.5674 3.4206 13.2612 3.4206H6.10605V7.37768H12.5952C12.9015 7.37768 13.1008 7.42489 13.1931 7.52361C13.2855 7.62232 13.3341 7.81116 13.3341 8.09871V9.08584C13.3341 9.3691 13.2855 9.56223 13.1931 9.65665C13.1008 9.75536 12.9015 9.80258 12.5952 9.80258V9.80687Z"
			fill="white"
		/>
		<path
			d="M19.4471 4.72038V5.90995C19.6826 5.36493 20.0379 4.90995 20.513 4.54502C20.988 4.18009 21.487 4 22.018 4H22.477C22.6846 4 22.8244 4.04739 22.8962 4.13744C22.9641 4.22749 23 4.41232 23 4.69668V6.03791C23 6.31754 22.9641 6.50711 22.8962 6.59716C22.8244 6.6872 22.6886 6.7346 22.477 6.7346H21.8902C21.1796 6.7346 20.6008 7.00948 20.1537 7.56398C19.7066 8.11848 19.483 8.84834 19.483 9.75829V16.2844C19.483 16.564 19.4431 16.7583 19.3593 16.8531C19.2754 16.9479 19.0958 17 18.8164 17H17.6667C17.4032 17 17.2236 16.9526 17.1357 16.8531C17.0439 16.7536 17 16.564 17 16.2844V4.72038C17 4.44076 17.0439 4.24645 17.1357 4.15166C17.2275 4.05213 17.4032 4.00474 17.6667 4.00474H18.7764C19.0559 4.00474 19.2355 4.05687 19.3194 4.15166C19.4032 4.25118 19.4431 4.44076 19.4431 4.72038H19.4471Z"
			fill="white"
		/>
		<path
			d="M29.7696 9.36585C30.1275 9.36585 30.5392 9.38049 31 9.40488C31.4609 9.42927 31.8949 9.46829 32.302 9.52195V8.85854C32.302 7.90244 32.132 7.2439 31.7964 6.87805C31.4609 6.5122 30.9418 6.32683 30.2394 6.32683C29.7562 6.32683 29.3579 6.40976 29.0447 6.57073C28.7315 6.73171 28.5123 7.00976 28.387 7.4C28.3244 7.60488 28.2483 7.73659 28.1544 7.79512C28.0604 7.85366 27.8904 7.88293 27.6398 7.88293H26.1186C25.868 7.88293 25.698 7.83415 25.604 7.74146C25.5101 7.64878 25.4653 7.50732 25.4653 7.32195C25.4653 6.65854 25.7069 6.03902 26.1902 5.45854C26.5794 5 27.094 4.63902 27.7383 4.38537C28.3781 4.13171 29.2148 4 30.2438 4C31.4787 4 32.4362 4.18049 33.1253 4.53659C33.8143 4.89268 34.2975 5.41951 34.5794 6.10732C34.8613 6.79512 35 7.6439 35 8.64878V13.6293C35 14.3805 34.8658 15.0878 34.6018 15.761C34.3378 16.4341 33.877 16.9756 33.2327 17.3854C32.5839 17.7951 31.6756 18 30.5034 18H29.8456C28.1902 18 26.9687 17.6634 26.1812 16.9902C25.3937 16.3171 25 15.2341 25 13.7317C25 12.6098 25.2148 11.7317 25.6443 11.1024C26.0738 10.4732 26.651 10.0244 27.3803 9.76098C28.1051 9.49756 28.906 9.36585 29.783 9.36585H29.7696ZM29.9083 11.6878C29.1745 11.6878 28.6242 11.839 28.2573 12.1463C27.8904 12.4537 27.7069 12.9707 27.7069 13.7024C27.7069 14.4341 27.8859 14.9463 28.2438 15.2341C28.6018 15.522 29.1119 15.6683 29.7651 15.6683H30.1633C30.9284 15.6683 31.4743 15.4634 31.8054 15.0537C32.132 14.6439 32.2975 13.9561 32.2975 12.9854V11.8341C31.783 11.7659 31.3266 11.722 30.9374 11.7073C30.5481 11.6927 30.2036 11.6829 29.9083 11.6829V11.6878Z"
			fill="white"
		/>
		<path
			d="M44.9525 16.8818V16.36C44.5342 16.8818 44.0113 17.2843 43.3889 17.5726C42.7614 17.8608 42.0593 18 41.2775 18C39.8334 18 38.7628 17.5776 38.0557 16.7377C37.3486 15.8978 37 14.7448 37 13.2886V4.75541C37 4.44231 37.0548 4.23855 37.1693 4.14412C37.2838 4.0497 37.5029 4 37.8316 4H39.2657C39.5944 4 39.8185 4.0497 39.933 4.14412C40.0475 4.23855 40.1023 4.44231 40.1023 4.75541V12.8214C40.1023 13.6216 40.2567 14.2329 40.5704 14.6553C40.8841 15.0827 41.4568 15.2914 42.2933 15.2914C43.1299 15.2914 43.7474 15.033 44.2105 14.5112C44.6686 13.9894 44.9027 13.2787 44.9027 12.3791V4.75541C44.9027 4.44231 44.9574 4.23855 45.072 4.14412C45.1865 4.0497 45.4056 4 45.7343 4H47.1684C47.4971 4 47.7211 4.0497 47.8307 4.14412C47.9452 4.23855 48 4.44231 48 4.75541V16.8818C48 17.1949 47.9452 17.3987 47.8307 17.4931C47.7162 17.5875 47.4971 17.6372 47.1684 17.6372H45.7841C45.4554 17.6372 45.2313 17.5875 45.1168 17.4931C45.0023 17.3987 44.9475 17.1949 44.9475 16.8818H44.9525Z"
			fill="white"
		/>
		<path
			d="M56.5531 5.07338C57.7757 5.07338 58.7159 5.42662 59.3777 6.12862V1.67964C59.3777 1.41583 59.4241 1.23251 59.5211 1.13861C59.618 1.04471 59.8035 1 60.0818 1H61.296C61.5911 1 61.7808 1.04918 61.8693 1.13861C61.9578 1.23251 62 1.41136 62 1.67964V12.7775C62 14.4498 61.5742 15.7375 60.7184 16.6407C59.8668 17.5484 58.6358 18 57.0337 18C55.4317 18 54.2428 17.5484 53.3449 16.6407C52.4469 15.7375 52 14.4945 52 12.9161V10.6223C52 8.84271 52.3879 7.47449 53.172 6.51315C53.9519 5.55181 55.0776 5.07338 56.5489 5.07338H56.5531ZM54.6307 10.6447V12.4466C54.6307 14.5839 55.4317 15.6526 57.0379 15.6526C58.6442 15.6526 59.4241 14.5839 59.4241 12.4466V10.667C59.4241 8.51184 58.6273 7.43424 57.0379 7.43424C55.4486 7.43424 54.6307 8.50289 54.6307 10.6402V10.6447Z"
			fill="white"
		/>
		<path
			d="M75.0633 12.5298C75.0813 12.2895 75.1401 12.1278 75.2395 12.0447C75.3434 11.9661 75.5331 11.9245 75.8178 11.9245H77.2364C77.5346 11.9245 77.738 11.9707 77.8373 12.0585C77.9413 12.1463 77.991 12.3219 77.991 12.5806C77.991 13.4353 77.887 14.1514 77.6837 14.7336C77.4804 15.3157 77.1416 15.8563 76.6672 16.3552C75.628 17.4548 74.0467 18 71.9367 18C69.3027 18 67.5045 16.905 66.5422 14.7105C66.3389 14.2438 66.1943 13.7218 66.1175 13.1489C66.0361 12.576 66 11.8229 66 10.885V7.35062C66 6.49589 66.0452 5.77053 66.131 5.17454C66.2169 4.57854 66.3569 4.02875 66.5467 3.52977C66.9714 2.38398 67.6762 1.51078 68.6521 0.905544C69.628 0.300308 70.8298 0 72.2485 0C74.2681 0 75.7636 0.540554 76.744 1.62166C77.2003 2.12064 77.5256 2.66119 77.7154 3.24333C77.9051 3.82546 78 4.52772 78 5.3501C78 5.60883 77.9503 5.77977 77.8464 5.87218C77.7425 5.95996 77.5437 6.00616 77.2455 6.00616H75.7771C75.4925 6.00616 75.2982 5.96458 75.1988 5.88604C75.0949 5.80749 75.0361 5.64579 75.0226 5.40092C75.0045 4.40298 74.7741 3.67762 74.3268 3.23409C73.8795 2.79055 73.1386 2.56879 72.113 2.56879C70.9608 2.56879 70.1476 2.90606 69.6777 3.57136C69.2033 4.24127 68.9684 5.3732 68.9684 6.97177V11.0375C68.9684 12.5852 69.2123 13.7033 69.7003 14.3871C70.1882 15.0708 70.988 15.4174 72.0904 15.4174C73.0843 15.4174 73.8208 15.1817 74.3042 14.7151C74.7831 14.2485 75.0407 13.5231 75.0723 12.539L75.0633 12.5298Z"
			fill="white"
		/>
		<path
			d="M90.2048 11.809H82.9714V11.9349C82.9714 13.2042 83.1762 14.1052 83.581 14.6381C83.9857 15.1709 84.6667 15.4374 85.6143 15.4374C86.8286 15.4374 87.5667 14.9965 87.8333 14.1197C87.9333 13.8339 88.1571 13.6886 88.5048 13.6886H90.0524C90.3333 13.6886 90.5333 13.7322 90.6524 13.8145C90.7714 13.8969 90.8286 14.0519 90.8286 14.2699C90.8286 14.5896 90.7238 14.9675 90.5143 15.3986C90.3048 15.8298 90.0619 16.1882 89.781 16.4789C89.3143 16.9682 88.7143 17.346 87.9714 17.6076C87.2333 17.8692 86.4191 18 85.5381 18C83.7762 18 82.4143 17.4913 81.4476 16.4789C80.4809 15.4664 80 14.018 80 12.1433V10.1668C80 8.20484 80.4809 6.68858 81.4476 5.61315C82.4095 4.53772 83.781 4 85.5619 4C87.3429 4 88.6905 4.49896 89.6143 5.49689C90.5381 6.49481 91 7.95779 91 9.88581V11.0775C91 11.3633 90.9524 11.5619 90.8524 11.6588C90.7524 11.7606 90.5381 11.809 90.2048 11.809ZM85.5905 6.43183C84.6952 6.43183 84.0381 6.67405 83.6333 7.15363C83.2238 7.63806 83.0048 8.42768 82.9714 9.52734H87.9857C87.9857 8.46159 87.7952 7.68166 87.4095 7.1827C87.0286 6.68374 86.419 6.43668 85.5905 6.43668V6.43183Z"
			fill="white"
		/>
		<path
			d="M99.72 4C101.161 4 102.234 4.39212 102.942 5.17175C103.646 5.95138 104 7.02165 104 8.37331V16.2988C104 16.5894 103.946 16.7786 103.833 16.8662C103.72 16.9539 103.503 17 103.178 17H101.762C101.437 17 101.216 16.9539 101.102 16.8662C100.989 16.7786 100.935 16.5894 100.935 16.2988V8.81157C100.935 8.06884 100.778 7.50142 100.458 7.1093C100.138 6.71256 99.5577 6.51881 98.7165 6.51881C97.8752 6.51881 97.2455 6.75869 96.7733 7.22924C96.301 7.7044 96.0648 8.3687 96.0648 9.22214V16.2988C96.0648 16.5894 96.0107 16.7786 95.8976 16.8662C95.7844 16.9539 95.568 17 95.2384 17H93.8216C93.4969 17 93.2755 16.9539 93.1673 16.8662C93.0541 16.7786 93 16.5894 93 16.2988V5.03797C93 4.74734 93.0541 4.5582 93.1673 4.47055C93.2804 4.3829 93.4969 4.33676 93.8216 4.33676H95.1892C95.5139 4.33676 95.7352 4.3829 95.8484 4.47055C95.9615 4.5582 96.0157 4.74734 96.0157 5.03797V5.54542C96.4092 5.06104 96.9307 4.68275 97.5751 4.41058C98.2196 4.1384 98.9378 4 99.725 4H99.72Z"
			fill="white"
		/>
		<path
			d="M114.291 17H112.681C110.995 17 109.835 16.6843 109.198 16.0486C108.566 15.4172 108.247 14.564 108.247 13.4889V7.47782H106.72C106.434 7.47782 106.242 7.43515 106.143 7.3541C106.044 7.27304 106 7.10666 106 6.85068V5.91212C106 5.66041 106.049 5.48976 106.143 5.4087C106.236 5.32765 106.429 5.28498 106.72 5.28498H108.247V2.64846C108.247 2.37969 108.308 2.20478 108.434 2.12372C108.56 2.04266 108.802 2 109.165 2H110.747C111.11 2 111.357 2.04266 111.478 2.12372C111.604 2.20478 111.665 2.37969 111.665 2.64846V5.28498H114.28C114.566 5.28498 114.758 5.32765 114.857 5.4087C114.951 5.48976 115 5.65614 115 5.91212V6.85068C115 7.10239 114.951 7.27304 114.857 7.3541C114.764 7.43515 114.571 7.47782 114.28 7.47782H111.665V13.0836C111.665 13.634 111.786 14.0393 112.022 14.2995C112.264 14.5597 112.67 14.692 113.247 14.692H114.28C114.566 14.692 114.758 14.7346 114.857 14.8157C114.951 14.8968 115 15.0631 115 15.3191V16.3686C115 16.6203 114.956 16.791 114.868 16.872C114.78 16.9531 114.582 16.9957 114.28 16.9957L114.291 17Z"
			fill="white"
		/>
		<path
			d="M119.263 4.72038V5.90995C119.577 5.36493 120.051 4.90995 120.684 4.54502C121.317 4.18009 121.983 4 122.691 4H123.303C123.58 4 123.766 4.04739 123.862 4.13744C123.952 4.22749 124 4.41232 124 4.69668V6.03791C124 6.31754 123.952 6.50711 123.862 6.59716C123.766 6.6872 123.585 6.7346 123.303 6.7346H122.52C121.573 6.7346 120.801 7.00948 120.205 7.56398C119.609 8.11848 119.311 8.84834 119.311 9.75829V16.2844C119.311 16.564 119.257 16.7583 119.146 16.8531C119.034 16.9479 118.794 17 118.422 17H116.889C116.538 17 116.298 16.9526 116.181 16.8531C116.059 16.7536 116 16.564 116 16.2844V4.72038C116 4.44076 116.059 4.24645 116.181 4.15166C116.303 4.05213 116.538 4.00474 116.889 4.00474H118.369C118.741 4.00474 118.981 4.05687 119.092 4.15166C119.204 4.25118 119.257 4.44076 119.257 4.72038H119.263Z"
			fill="white"
		/>
		<path
			d="M129.293 9.36585C129.615 9.36585 129.985 9.38049 130.4 9.40488C130.815 9.42927 131.205 9.46829 131.572 9.52195V8.85854C131.572 7.90244 131.419 7.2439 131.117 6.87805C130.815 6.5122 130.348 6.32683 129.715 6.32683C129.281 6.32683 128.922 6.40976 128.64 6.57073C128.358 6.73171 128.161 7.00976 128.048 7.4C127.992 7.60488 127.923 7.73659 127.839 7.79512C127.754 7.85366 127.601 7.88293 127.376 7.88293H126.007C125.781 7.88293 125.628 7.83415 125.544 7.74146C125.459 7.64878 125.419 7.50732 125.419 7.32195C125.419 6.65854 125.636 6.03902 126.071 5.45854C126.421 5 126.885 4.63902 127.464 4.38537C128.04 4.13171 128.793 4 129.719 4C130.831 4 131.693 4.18049 132.313 4.53659C132.933 4.89268 133.368 5.41951 133.621 6.10732C133.875 6.79512 134 7.6439 134 8.64878V13.6293C134 14.3805 133.879 15.0878 133.642 15.761C133.404 16.4341 132.989 16.9756 132.409 17.3854C131.826 17.7951 131.008 18 129.953 18H129.361C127.871 18 126.772 17.6634 126.063 16.9902C125.354 16.3171 125 15.2341 125 13.7317C125 12.6098 125.193 11.7317 125.58 11.1024C125.966 10.4732 126.486 10.0244 127.142 9.76098C127.795 9.49756 128.515 9.36585 129.305 9.36585H129.293ZM129.417 11.6878C128.757 11.6878 128.262 11.839 127.932 12.1463C127.601 12.4537 127.436 12.9707 127.436 13.7024C127.436 14.4341 127.597 14.9463 127.919 15.2341C128.242 15.522 128.701 15.6683 129.289 15.6683H129.647C130.336 15.6683 130.827 15.4634 131.125 15.0537C131.419 14.6439 131.568 13.9561 131.568 12.9854V11.8341C131.105 11.7659 130.694 11.722 130.344 11.7073C129.993 11.6927 129.683 11.6829 129.417 11.6829V11.6878Z"
			fill="white"
		/>
		<path
			d="M141 1.65219V16.3478C141 16.6181 140.947 16.794 140.836 16.8756C140.726 16.9571 140.514 17 140.191 17H138.804C138.486 17 138.27 16.9571 138.164 16.8756C138.053 16.794 138 16.6181 138 16.3478V1.65219C138 1.38187 138.053 1.20595 138.164 1.12443C138.274 1.04291 138.486 1 138.804 1H140.191C140.509 1 140.726 1.04291 140.836 1.12443C140.947 1.20595 141 1.38187 141 1.65219Z"
			fill="white"
		/>
		<path
			d="M146 13C146.608 13 147.094 13.191 147.458 13.573C147.821 13.9551 148 14.4775 148 15.1461V15.8539C148 16.5225 147.821 17.0449 147.458 17.427C147.094 17.809 146.608 18 146 18C145.392 18 144.906 17.809 144.542 17.427C144.179 17.0449 144 16.5225 144 15.8539V15.1461C144 14.4775 144.179 13.9551 144.542 13.573C144.906 13.191 145.392 13 146 13Z"
			fill="white"
		/>
		<path
			d="M152.49 3.30506C152.038 3.30506 151.674 3.17966 151.406 2.92887C151.134 2.67808 151 2.33325 151 1.89884V1.40622C151 0.953899 151.134 0.604584 151.406 0.36275C151.678 0.120917 152.038 0 152.49 0C152.941 0 153.326 0.120917 153.594 0.36275C153.866 0.604584 154 0.953899 154 1.40622V1.89884C154 2.33772 153.866 2.68256 153.594 2.92887C153.322 3.17966 152.954 3.30506 152.49 3.30506ZM153.782 5.39199V16.3193C153.782 16.6014 153.736 16.785 153.64 16.8701C153.544 16.9552 153.36 17 153.079 17H151.874C151.598 17 151.41 16.9552 151.318 16.8701C151.222 16.785 151.176 16.6014 151.176 16.3193V5.39199C151.176 5.10985 151.222 4.92624 151.318 4.84115C151.414 4.75606 151.598 4.71128 151.874 4.71128H153.079C153.356 4.71128 153.544 4.75606 153.64 4.84115C153.736 4.92624 153.782 5.10985 153.782 5.39199Z"
			fill="white"
		/>
		<path
			d="M162.5 18C160.77 18 159.424 17.5059 158.452 16.5176C157.486 15.5294 157 14.1439 157 12.3709V9.88581C157 8.02561 157.486 6.57716 158.452 5.54533C159.419 4.5135 160.77 4 162.5 4C164.23 4 165.576 4.49412 166.548 5.48235C167.514 6.47059 168 7.85606 168 9.62907V12.1142C168 13.9599 167.514 15.3986 166.548 16.4401C165.581 17.4817 164.23 18 162.5 18ZM162.5 15.4422C164.244 15.4422 165.118 14.2941 165.118 11.9931V10.0166C165.118 7.71557 164.249 6.56747 162.5 6.56747C160.751 6.56747 159.882 7.72042 159.882 10.0166V11.9931C159.882 14.2941 160.751 15.4422 162.5 15.4422Z"
			fill="white"
		/>
		<path
			d="M33.8649 178.2L54.1839 154.602C47.588 147.773 43.2549 138.748 42.5056 128.596C40.7964 105.439 58.398 85.2047 81.751 83.481C105.104 81.7573 125.537 99.2015 127.247 122.366C128.957 145.531 111.355 165.757 88.0018 167.481C78.0774 168.213 68.6915 165.481 61.0274 160.321L40.606 184.02C38.9406 185.953 36.2599 186.151 34.3287 184.483C32.3975 182.816 32.1995 180.133 33.8649 178.2V178.2ZM122.508 122.716C120.993 102.192 102.88 86.7674 82.1572 88.297C61.4344 89.8265 45.7832 107.752 47.2974 128.267C48.8116 148.783 66.9252 164.216 87.6479 162.686C108.371 161.157 124.02 143.206 122.508 122.716Z"
			fill="#408DDC"
		/>
		<path
			d="M140.508 54.6407C140.508 59.9823 140.689 65.3326 140.439 70.6569C140.292 73.8393 141.155 74.9406 144.477 74.8365C152.079 74.5937 159.706 74.9146 167.308 74.6891C170.492 74.5937 170.708 76.1025 170.699 78.5999C170.691 81.158 170.699 82.9183 167.188 82.8403C156.85 82.6148 146.505 82.6755 136.167 82.8142C133.372 82.8489 132.457 82.0251 132.483 79.1636C132.604 65.3066 132.638 51.4496 132.431 37.6014C132.379 34.1328 134.01 34.0894 136.599 34.0027C139.446 33.916 140.723 34.549 140.585 37.7661C140.335 43.3852 140.516 49.0216 140.516 54.6494H140.499L140.508 54.6407Z"
			:fill="`url(#paint0_linear_1_2744_${id})`"
		/>
		<path
			d="M39.5595 58.3698C39.5595 65.437 39.4819 72.5042 39.6027 79.5714C39.6458 82.0081 38.8434 82.8146 36.4101 82.7972C25.7881 82.6932 15.1662 82.6325 4.54425 82.8146C0.997856 82.8753 1.60187 80.7334 1.48969 78.6002C1.35163 76.1202 1.64501 74.5854 4.84626 74.6807C12.1634 74.9062 19.4978 74.49 26.7977 74.8542C30.9654 75.0623 31.7592 73.5361 31.6815 69.738C31.4399 59.0722 31.7074 48.3889 31.509 37.7231C31.4486 34.4713 32.7342 33.9337 35.5386 33.9944C38.1531 34.0464 39.8098 34.1504 39.6804 37.6017C39.4129 44.5128 39.6027 51.4413 39.6027 58.3698H39.5595Z"
			:fill="`url(#paint1_linear_1_2744_${id})`"
		/>
		<path
			d="M92.031 198.301C92.031 190.367 92.1518 182.432 91.9706 174.498C91.9016 171.463 92.4711 170.127 95.9226 170.179C99.1152 170.232 100.073 171.073 100.038 174.342C99.8745 189.924 99.9781 205.498 99.9522 221.081C99.9522 223.413 100.142 225.417 101.859 227.411C104.448 230.42 103.887 235.103 101.195 237.635C98.4422 240.219 93.7568 240.271 90.9438 237.747C88.2172 235.293 87.5441 230.515 90.0723 227.541C91.7635 225.555 92.0742 223.587 92.0569 221.228C91.9965 213.58 92.0397 205.94 92.0397 198.292L92.031 198.301Z"
			:fill="`url(#paint2_linear_1_2744_${id})`"
		/>
		<path
			d="M60.079 193.644C60.079 198.396 60.0531 203.148 60.0876 207.909C60.1135 211.577 59.8287 215.071 62.4432 218.471C64.7989 221.532 63.565 225.122 60.6139 227.688C58.0685 229.9 53.6851 229.813 51.0706 227.662C48.1627 225.269 47.0755 220.959 49.5088 218.002C51.7091 215.323 51.6919 212.565 51.6919 209.574C51.6919 197.763 51.8385 185.944 51.6056 174.142C51.5365 170.57 53.0207 170.257 55.9199 170.223C58.9141 170.188 60.3292 170.708 60.1739 174.203C59.8891 180.672 60.0876 187.167 60.0876 193.644H60.079Z"
			:fill="`url(#paint3_linear_1_2744_${id})`"
		/>
		<path
			d="M80.0043 205.03C80.0043 215.549 79.9525 226.076 80.0388 236.594C80.0647 239.274 79.5642 239.967 76.9756 238.476C73.7571 236.62 72.04 234.582 72.109 230.377C72.4024 211.785 72.2816 193.194 72.1953 174.602C72.1781 171.775 72.3765 170.197 75.992 170.154C79.7713 170.11 80.0906 171.723 80.0474 174.758C79.9094 184.843 79.9957 194.937 79.9957 205.022L80.0043 205.03Z"
			:fill="`url(#paint4_linear_1_2744_${id})`"
		/>
		<path
			d="M116.227 81.7306C112.707 82.5457 111.835 80.9675 111.93 77.5857C112.154 69.8161 111.999 62.0291 111.99 54.2508C111.99 50.5655 112.249 47.0449 109.505 43.7584C107.305 41.1136 108.642 36.6218 111.352 34.4713C113.923 32.4248 118.98 32.5462 121.378 34.7054C124.01 37.0727 124.933 41.6946 122.569 44.5041C120.541 46.9148 120.507 49.3688 120.507 52.0916C120.524 60.5897 120.36 69.0963 120.576 77.5857C120.662 81.0022 119.722 82.511 116.236 81.7306H116.227Z"
			:fill="`url(#paint5_linear_1_2744_${id})`"
		/>
		<path
			d="M112.111 226.838C112.111 208.013 112.145 190.453 112.085 172.885C112.076 169.399 114.509 170.413 116.296 170.344C118.194 170.275 120.394 169.668 120.368 173.049C120.273 187.904 120.437 202.758 120.256 217.612C120.204 222.251 115.493 223.24 112.102 226.838H112.111Z"
			:fill="`url(#paint6_linear_1_2744_${id})`"
		/>
		<path
			d="M76.0161 81.7308C72.5559 82.5025 71.9174 80.7769 71.9692 77.7072C72.1245 68.7756 71.9864 59.8441 72.021 50.9211C72.021 48.8573 71.719 47.1317 70.2952 45.3888C67.629 42.1196 68.0863 37.4544 70.8992 34.8356C73.4706 32.4336 78.5788 32.3816 81.1588 34.7229C84.2047 37.4891 84.6189 42.111 81.7196 45.5882C80.3649 47.2184 80.1233 48.814 80.1233 50.713C80.1492 59.6446 80.0198 68.5762 80.1837 77.4991C80.2441 80.6902 79.6833 82.6239 76.0074 81.7394L76.0161 81.7308Z"
			:fill="`url(#paint7_linear_1_2744_${id})`"
		/>
		<path
			d="M27.4805 103.409C24.331 103.409 21.1729 103.461 18.0235 103.392C15.8404 103.348 14.0025 103.634 12.1301 105.195C8.95469 107.857 4.73526 107.311 2.0431 104.441C-0.580028 101.631 -0.692201 97.1047 1.79287 94.1651C4.24342 91.2688 8.7476 90.4451 11.8712 92.9684C14.0111 94.6941 16.1338 94.9369 18.593 94.9195C25.332 94.8675 32.071 95.0929 38.8014 94.8241C42.5549 94.6767 42.0372 96.914 42.1148 99.2379C42.2011 101.753 42.2356 103.773 38.6633 103.478C34.9616 103.175 31.2168 103.409 27.4892 103.409H27.4805Z"
			:fill="`url(#paint8_linear_1_2744_${id})`"
		/>
		<path
			d="M60.0535 57.9358C60.0535 64.7082 59.881 71.4893 60.1226 78.253C60.2434 81.6349 58.6557 81.9817 55.9808 81.8777C53.5906 81.7823 51.5802 82.1292 51.6319 78.4698C51.8131 64.7863 51.8045 51.0941 51.6147 37.4105C51.5629 33.6992 53.6424 34.1501 55.9894 34.046C58.6384 33.9246 60.2779 34.2195 60.1485 37.636C59.8982 44.3997 60.0708 51.1808 60.0708 57.9532H60.0535V57.9358Z"
			:fill="`url(#paint9_linear_1_2744_${id})`"
		/>
		<path
			d="M27.869 144.043C24.9957 144.043 22.1223 144.113 19.2576 144.026C17.1867 143.965 15.4782 144.416 13.7093 145.717C10.1371 148.327 5.77092 147.703 3.25134 144.659C0.740388 141.615 0.947477 137.297 3.74318 134.444C6.66831 131.461 10.4908 131.218 14.158 134.193C15.6853 135.433 17.1694 135.606 18.9038 135.597C25.5048 135.58 32.123 135.797 38.7153 135.511C42.3912 135.354 42.0719 137.427 42.1064 139.838C42.1409 142.344 42.2272 144.407 38.6377 144.113C35.074 143.818 31.4672 144.052 27.8777 144.052L27.869 144.043Z"
			:fill="`url(#paint10_linear_1_2744_${id})`"
		/>
		<path
			d="M144.193 115.436C147.213 115.436 150.233 115.393 153.253 115.454C155.721 115.497 157.869 115.246 160 113.459C163.098 110.858 168.103 111.69 170.476 114.639C172.866 117.613 172.745 121.697 170.174 124.559C167.447 127.594 163.064 128.019 159.75 125.192C158.154 123.822 156.575 123.509 154.651 123.518C147.61 123.561 140.56 123.353 133.519 123.613C129.955 123.744 130.05 121.844 130.033 119.33C130.024 116.867 130.188 115.15 133.407 115.384C136.979 115.644 140.595 115.445 144.184 115.445L144.193 115.436Z"
			:fill="`url(#paint11_linear_1_2744_${id})`"
		/>
		<path
			d="M99.9355 58.1446C99.9355 64.7782 99.7802 71.4205 100.005 78.0542C100.108 81.1412 99.0813 82.0084 96.1044 81.913C93.4467 81.8349 91.9453 81.6528 91.9885 78.271C92.1697 64.7088 92.1697 51.1467 91.9626 37.5933C91.9108 34.142 93.5071 34.0813 96.1044 33.9946C99.0381 33.8905 100.134 34.6796 100.03 37.81C99.7975 44.5824 99.9614 51.3722 99.9614 58.1446H99.9355Z"
			:fill="`url(#paint12_linear_1_2744_${id})`"
		/>
		<path
			d="M142.363 164.161C139.541 164.161 137.384 164.161 135.236 164.161C129.774 164.161 129.903 164.161 130.058 158.663C130.127 156.322 131.007 155.689 133.139 155.75C137.013 155.854 140.887 155.793 144.762 155.776C148.282 155.758 151.656 156.07 154.874 153.486C157.575 151.318 162.683 152.862 164.521 155.515C166.635 158.55 166.299 162.964 163.779 165.462C161.148 168.072 156.807 168.67 154.003 166.095C150.163 162.565 145.737 165.037 142.363 164.161Z"
			:fill="`url(#paint13_linear_1_2744_${id})`"
		/>
		<path
			d="M149.922 103.357C144.321 103.357 138.704 103.183 133.113 103.417C129.549 103.565 130.084 101.328 130.075 99.1857C130.075 97.0352 129.584 94.8934 133.121 94.9454C144.615 95.1101 156.108 95.1188 167.602 94.9454C171.139 94.8934 170.639 97.0439 170.639 99.1944C170.639 101.336 171.148 103.556 167.593 103.417C161.708 103.183 155.815 103.357 149.922 103.357Z"
			:fill="`url(#paint14_linear_1_2744_${id})`"
		/>
		<path
			d="M155.462 176.336C153.702 182.667 150.414 185.832 144.106 184.678C141.363 184.175 139.999 185.389 140.448 188.251C140.897 191.113 139.352 194.208 141.647 196.879C142.277 197.616 141.199 198.423 140.672 199.038C138.36 201.761 135.995 204.458 133.717 207.094C132.035 206.47 132.648 205.282 132.639 204.432C132.587 196.22 132.639 188.017 132.587 179.805C132.579 177.741 132.63 176.206 135.443 176.276C142.018 176.44 148.593 176.328 155.453 176.328L155.462 176.336Z"
			:fill="`url(#paint15_linear_1_2744_${id})`"
		/>
		<path
			d="M19.6712 48.4844C19.6712 52.2391 19.5763 55.9852 19.6971 59.7312C19.7662 61.9078 19.1535 62.8616 16.8065 62.7749C12.6475 62.6188 8.47118 62.5755 4.32077 62.7749C0.57591 62.957 1.61135 60.3469 1.51644 58.4045C1.40427 56.2453 1.03323 54.1815 4.54512 54.1555C11.172 54.0948 11.1633 53.8867 11.1633 47.123C11.1633 43.6631 11.241 40.1945 11.1461 36.7346C11.0425 32.9105 13.8469 34.2285 15.702 34.1158C17.5745 34.0031 20.0509 33.3874 19.6712 36.7953C19.654 36.934 19.6712 37.0814 19.6712 37.2289C19.6712 40.9836 19.6712 44.7297 19.6712 48.4844Z"
			:fill="`url(#paint16_linear_1_2744_${id})`"
		/>
		<path
			d="M160.949 44.4344C160.949 45.1542 160.949 45.8739 160.949 46.5936C160.949 53.6782 160.94 53.8603 167.921 54.1638C171.657 54.3285 170.544 56.7999 170.613 58.7163C170.682 60.5893 171.364 62.9219 167.947 62.7745C163.796 62.5924 159.62 62.6445 155.461 62.7745C153.287 62.8439 152.406 62.2022 152.441 59.8609C152.553 52.2214 152.614 44.5732 152.415 36.9336C152.32 33.1876 154.797 34.1588 156.747 34.0894C158.843 34.02 161.268 33.4911 160.983 37.0811C160.794 39.5177 160.949 41.9804 160.949 44.4344Z"
			:fill="`url(#paint17_linear_1_2744_${id})`"
		/>
		<path
			d="M16.6343 176.328C23.4682 176.328 29.9138 176.423 36.3595 176.284C38.8014 176.232 39.578 177.056 39.5435 179.493C39.4313 188.563 39.5003 197.642 39.5003 207.406C35.5398 204.692 32.3471 201.492 30.8975 197.798C30.1209 195.821 31.5102 192.274 31.7431 189.387C32.0451 185.511 30.8975 184.019 26.7816 184.184C19.7751 184.479 19.6716 184.167 16.6343 176.319V176.328Z"
			:fill="`url(#paint18_linear_1_2744_${id})`"
		/>
		<path
			d="M21.8017 123.422C16.0549 123.422 10.2996 123.249 4.56149 123.483C0.989208 123.631 1.60185 121.402 1.51556 119.269C1.42927 117.058 1.46379 115.445 4.55286 115.488C16.0463 115.644 27.5484 115.662 39.0418 115.488C42.6055 115.436 42.0015 117.578 42.0877 119.702C42.174 121.914 42.1481 123.596 39.0504 123.483C33.3123 123.266 27.557 123.422 21.8103 123.422H21.8017Z"
			:fill="`url(#paint19_linear_1_2744_${id})`"
		/>
		<path
			d="M149.809 135.615C155.125 135.615 160.449 135.589 165.764 135.632C167.239 135.641 169.327 135.146 168.974 137.765C168.68 139.968 169.681 143.393 166.04 143.462C154.831 143.67 143.614 143.566 132.405 143.532C128.928 143.523 130.343 140.757 130.153 139.048C129.955 137.34 129.799 135.536 132.561 135.589C138.307 135.71 144.063 135.623 149.809 135.623V135.615Z"
			:fill="`url(#paint20_linear_1_2744_${id})`"
		/>
		<path
			d="M7.38412 156.288C18.6273 156.288 28.8351 156.409 39.0256 156.227C42.5979 156.166 41.9421 158.351 42.0543 160.467C42.1751 162.757 41.9852 164.257 38.9479 164.187C30.3279 163.997 21.6991 164.153 13.0704 164.109C7.8587 164.083 9.25655 159.427 7.37549 156.288H7.38412Z"
			:fill="`url(#paint21_linear_1_2744_${id})`"
		/>
		<path
			d="M66.2939 130.617C66.2939 130.617 70.491 130.617 76.087 140.41C76.087 140.41 91.6407 114.762 105.466 109.632"
			:stroke="`url(#paint22_linear_1_2744_${id})`"
			stroke-width="7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<defs>
			<linearGradient
				:id="`paint0_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint1_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint2_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint3_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint4_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint5_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint6_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint7_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint8_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint9_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint10_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint11_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint12_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint13_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint14_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint15_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint16_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint17_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint18_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint19_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint20_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint21_linear_1_2744_${id}`"
				x1="86.0943"
				y1="33"
				x2="86.0943"
				y2="239.607"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				:id="`paint22_linear_1_2744_${id}`"
				x1="83.9434"
				y1="97.5705"
				x2="83.9434"
				y2="153.531"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#59ACFF" stop-opacity="0.51" />
			</linearGradient>
		</defs>
	</svg>
</template>
