<script setup>
import { getRuntimeUniqueId } from '@/Utils/helpers';
const id = getRuntimeUniqueId();
</script>

<template>
	<svg
		width="377"
		height="658"
		viewBox="0 0 377 658"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g :filter="`url(#filter0_b_1_2725_${id})`">
			<rect
				width="377"
				height="658"
				rx="24"
				:fill="`url(#paint0_linear_1_2725_${id})`"
			/>
		</g>
		<defs>
			<filter
				:id="`filter0_b_1_2725_${id}`"
				x="-15"
				y="-15"
				width="407"
				height="688"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
				<feComposite
					in2="SourceAlpha"
					operator="in"
					result="effect1_backgroundBlur_1_2725"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_backgroundBlur_1_2725"
					result="shape"
				/>
			</filter>
			<linearGradient
				:id="`paint0_linear_1_2725_${id}`"
				x1="159.708"
				y1="321.562"
				x2="-54.4583"
				y2="368.999"
				gradientUnits="userSpaceOnUse"
			>
				<stop />
				<stop offset="1" stop-color="#3F2C3F" />
			</linearGradient>
		</defs>
	</svg>
</template>
