<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import TextInput from '@/Components/TextInput.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import FraudCentralIoVerticalLogo from '@/Components/Logo/FraudCentralIoVerticalLogo.vue';
import LogoSideGradientBackground from '@/Components/Auth/LogoSideGradientBackground.vue';
import DigitalPrintEffectLogoBackground from '@/Components/Auth/DigitalPrintEffectLogoBackground.vue';

defineProps({
	status: {
		type: String,
	},
});

const form = useForm({
	email: '',
	password: '',
	remember: false,
});

function submit() {
	form.post('/login', {
		onFinish: () => form.reset('password'),
	});
}
</script>

<template>
	<Head title="Log in" />

	<!-- Login Container -->
	<div
		class="relative z-10 flex w-full flex-col rounded-3xl pt-[100px] min-[702px]:w-[660px] min-[702px]:flex-row min-[702px]:bg-black min-[702px]:pt-0"
	>
		<!-- Left side (Logo) -->
		<div class="relative z-0 hidden grow justify-center pt-14 min-[702px]:flex">
			<!-- Background Gradient -->
			<div class="absolute -left-[9.5px] bottom-0 h-full w-[313px]">
				<LogoSideGradientBackground class="h-full w-full" />
			</div>
			<!-- Logo and digital print -->
			<div class="relative w-[130px]">
				<!-- Digital Print background -->
				<div class="absolute -left-1/2 -top-[48px] h-full w-[260px]">
					<DigitalPrintEffectLogoBackground />
				</div>
				<!-- Logo -->
				<div class="flex w-[130px] justify-center">
					<FraudCentralIoVerticalLogo />
				</div>
			</div>
		</div>
		<!-- PHONE: Bottom (Logo)  -->
		<div
			class="absolute left-1/2 top-[70px] z-30 flex h-[150px] w-[150px] grow -translate-x-1/2 -translate-y-1/2 justify-center overflow-hidden rounded-xl border-4 border-[#2b0b27] bg-slate-800 min-[702px]:hidden"
		>
			<!-- Background Gradient -->
			<div
				class="absolute bottom-[50px] left-1/2 w-[150px] -translate-x-1/2 translate-y-1/2"
			>
				<LogoSideGradientBackground class="h-full w-full" />
			</div>
			<!-- Logo and digital print -->
			<div class="relative bottom-[50px] w-[80px]">
				<!-- Digital Print background -->
				<div
					class="absolute -left-[40px] top-[48px] h-[248px] w-[263px] opacity-85"
				>
					<DigitalPrintEffectLogoBackground />
				</div>
				<!-- Logo -->
				<div
					class="absolute bottom-[30px] left-0 z-40 flex h-[110px] w-[85px] justify-center"
				>
					<FraudCentralIoVerticalLogo />
				</div>
			</div>
		</div>
		<!-- Right side {phone:bottom} (Form) -->
		<div
			class="z-10 rounded-3xl bg-[#2C1230] px-8 py-14 min-[386px]:w-[380px] min-[702px]:w-[400px] min-[702px]:px-12 min-[702px]:py-14"
		>
			<!-- Welcome back title -->
			<h1 class="text-[2.1rem] leading-9">Welcome back to your account</h1>
			<p class="mt-2 text-[0.8rem] text-gray-89">
				Enter your email and password to sign in!
			</p>
			<form class="mt-6" @submit.prevent="submit">
				<div>
					<InputLabel class="mb-1" for="email" value="Email" />

					<TextInput
						class="border border-transparent focus:border-[#C53FA7] focus:!shadow-lg focus:!shadow-white/15"
						id="email"
						type="email"
						v-model="form.email"
						required
						autofocus
						autocomplete="username"
						placeholder="Email"
					/>

					<InputError :message="form.errors.email" />
				</div>

				<div class="mt-4">
					<InputLabel class="mb-1" for="password" value="Password" />

					<TextInput
						class="password border border-transparent focus:border-[#C53FA7] focus:!shadow-lg focus:!shadow-white/15"
						id="password"
						type="password"
						v-model="form.password"
						required
						autocomplete="current-password"
						placeholder="Password"
					/>

					<InputError :message="form.errors.password" />
				</div>

				<div class="mt-3 flex justify-between">
					<label class="flex cursor-pointer items-center">
						<Checkbox
							class="size-[0.93rem] cursor-pointer rounded-[0.2rem] border border-[#675C65] bg-gradient-to-b from-[#1A1A1A]/50 to-[#808080]/50 !text-[#C53FA7] shadow-sm focus:!ring-[#C53FA7] focus:!ring-offset-gray-800"
							name="remember"
							v-model:checked="form.remember"
						/>
						<span class="ml-2 text-xs text-gray-300/90">Remember me</span>
					</label>
					<Link
						class="cursor-pointer rounded-md p-1 text-xs font-bold text-gray-300/90 underline hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-[#C53FA7]"
						href="/forgot-password"
					>
						Forgot your password?
					</Link>
				</div>

				<div class="mb-1.5 mt-5 flex items-center justify-end">
					<button
						class="f w-full cursor-pointer rounded-lg bg-gradient-to-r from-[#C53FA7] to-[#B868EC] px-3 py-4 text-base font-bold hover:from-[#df39bb] hover:to-[#c26dfb] focus:ring-2 focus:ring-[#C53FA7]"
						type="submit"
						:class="{ 'opacity-25': form.processing }"
						:disabled="form.processing"
					>
						Sign In
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<style scoped>
.password {
	box-shadow: 0px 0px 0px 10% rgba(255, 255, 255, 0);
}
.password:focus {
	box-shadow: 0px 4px 0px 2px rgba(255, 255, 255, 0.1);
}
</style>
